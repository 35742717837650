import { TableList } from './../components/table/tableComponent';
import { AccountHeader } from './../components/header/accountHeaderComponent';
import { AccountProvider }  from '../hooks/useAccount'; 

const AccountPage = () => {
  return (
    <>
      <AccountProvider>
        <AccountHeader></AccountHeader>
        <TableList></TableList>
      </AccountProvider>
    </>
  );
}

export { AccountPage };