import { useState, useEffect, useCallback } from 'react';
import { useAccount } from '../../hooks/useAccount';
import { IAccountData } from '../../shared/interfaces/IAccountData';
import { IChangeBooleanEvent, IChangeStringEvent } from '../../shared/interfaces/IChangeEvent';
import { AccountModal } from './../modal/accountModalComponent';

const AccountHeader = () => {
  const { accountList, setFilteredAccountList } = useAccount();
  const [search, setSearch] = useState("");
  const [onlyActive, setOnlyActive] = useState(false);

  const handleSearchInputChange = (event: IChangeStringEvent) => {
    setSearch(event.target!.value!);
  };

  const handleOnlyActiveFilterChange = (event: IChangeBooleanEvent) => {
    setOnlyActive(event.target!.checked!);
  };

  const filterEquivalentAccount =  useCallback((account: IAccountData) => {
    return account.dealerNumberId.toString().toLowerCase().includes(search.toLowerCase())
        || account.fordUsername.toString().toLowerCase().includes(search.toLowerCase())
        || account.companyName.toString().toLowerCase().includes(search.toLowerCase()) 
  }, [search]);


  useEffect(() => {
    if (onlyActive) {
      setFilteredAccountList(
        accountList
          .filter((account: IAccountData) => filterEquivalentAccount(account) )
          .filter((account: IAccountData) => (account.active === onlyActive))
      );
    } else {
      setFilteredAccountList(
        accountList
          .filter((account: IAccountData) => filterEquivalentAccount(account) )
      );
    }
  },[search, onlyActive, accountList, setFilteredAccountList, filterEquivalentAccount])

  return (
    <>
      <div className="navbar">
        <div className="navbar-start">
          <div className="ml-6 mt-5 flex align-middle">   
            <p>Somente ativos</p>
            <input
              type="checkbox"
              name="active"
              onChange={handleOnlyActiveFilterChange}
              className="toggle ml-1"
            />
          </div>
        </div>
        <div className="navbar-end">
          <div className="mr-2">
            <label htmlFor="newAccountModal" className="btn modal-button">Adicionar Conta</label>
            <AccountModal isNewAccount />
          </div>
          <div className="form-control mr-2">
            <input type="text" placeholder="Buscar" className="input input-bordered" onChange={handleSearchInputChange} />
          </div>
          <button className="btn btn-ghost btn-circle">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" /></svg>
          </button>
        </div>
      </div>
      
    </>
  );
}

export { AccountHeader };