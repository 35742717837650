import React from 'react';
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";
import "./App.css";
import { AccountPage } from './pages/accountPage';
import { NavBar } from './components/navbar/navbarComponent';
import { AuthProvider, ProtectedRouteProvider } from  './shared/helpers/AuthProvider';
  
function App() {
  return (
    <>
      <AuthProvider>
        <NavBar></NavBar>
        <BrowserRouter>
          <Routes>
            <Route path="/*" element={<Navigate to="/conta" replace />} /> 
            <Route path="/conta" element={ 
              <ProtectedRouteProvider>
                <AccountPage/>
              </ProtectedRouteProvider>
            }/>
          </Routes>
        </BrowserRouter>
      </AuthProvider>
    </>
  );
}

export default App;
