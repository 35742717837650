import { useKeycloak } from "@react-keycloak/web";

const NavBar = () => {
  return (
    <>
      <div className="navbar bg-base-300 sticky top-0 z-50">
        <div className="navbar-start">
          <div className="dropdown">
            <label tabIndex={0} className="btn btn-ghost btn-circle">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h7"/></svg>
            </label>
            <ul tabIndex={0} className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52">
              <li> <a href="/conta">Contas</a> </li>
            </ul>
          </div>
        </div>
        <div className="navbar-center">
          <a className="btn btn-ghost normal-case text-xl" href="/">Ford SFA</a>
        </div>
        <div className="navbar-end">
          <LoginLogoutButton/>
        </div>
      </div>
    </>
  );
}

const LoginLogoutButton = () => {
  const { keycloak } = useKeycloak();

  if ( !keycloak.authenticated ) {
    return (
      <button type="button" className="btn btn-outline" onClick={() => keycloak.login()}>
        Login
      </button>
    );
  } else {
    return (
      <button type="button" className="btn btn-outline" onClick={() => keycloak.logout()}  >
        Logout
      </button>
    );
  }
}

export { NavBar };