import Keycloak from "keycloak-js";
import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { useEffect, StrictMode } from "react";
import { IReactChildren } from "../interfaces/IReactChildren";

const url = window.location.hostname === "ford-sfa.prod.syonet.com"
    ? "https://keycloak.dallas.linode.syonet.com/auth/"
    : "https://keycloak.internal.syonet.com/auth/";

const clientId = window.location.hostname === "ford-sfa.prod.syonet.com"
    ? "ford-sfa.prod.syonet.com"
    : "syonet-ford-sfa(develop)";

const adminAuthProviderInitConfig: Keycloak.KeycloakInitOptions = {
  onLoad: "check-sso"
}

const keycloak = new Keycloak({
  url,
  realm: "master",
  clientId
});

const AuthProvider = ({ children }: IReactChildren) => {
  return (
    <ReactKeycloakProvider 
      authClient={ keycloak }
      initOptions={ adminAuthProviderInitConfig }>
        <StrictMode>
          {children}
        </StrictMode>
    </ReactKeycloakProvider>
  );
};

const ProtectedRouteProvider = ({ children }: IReactChildren) => {
  const { keycloak, initialized } = useKeycloak();

  useEffect(() => {
    if (initialized) {
      const isAuth =
        keycloak.authenticated &&
        keycloak.tokenParsed?.exp &&
        keycloak.tokenParsed.exp * 1000 > Date.now() &&
        keycloak.token;

      if (!isAuth) {
        keycloak.login();
      }
    }
  }, [initialized, keycloak, keycloak.authenticated]);

  return keycloak.authenticated ? <>{children}</> : <LoginRequired />;
};

const LoginRequired = () => {
  return (
    <div className="grid grid-rows justify-center align-middle mt-20">
      <h1>Efetue o login para acessar esta página!</h1>
    </div>
  );
};

export { AuthProvider, ProtectedRouteProvider };