import { getFormattedDate } from '../../shared/utils/date';
import { AccountModal } from './../modal/accountModalComponent';
import { useAccount } from '../../hooks/useAccount';
import { IAccountData } from '../../shared/interfaces/IAccountData';
import { Loading } from '../loading/loadingComponent';

const TableList = () => {
  const { filteredAccountList, setSelectedAccount, isLoading } = useAccount();

  if (isLoading) {
    return <Loading/>;
  }

  return (
    <div className="overflow-x-auto ml-5 mr-5">
      <table className="table w-full">
        <thead>
          <tr>
            <th></th>
            <th>Código do dealer</th>
            <th>Usuario</th>
            <th>Senha</th>
            <th>Loja</th>
            <th>Ultima sincronização</th>
            <th>Status da conta</th>
            <th>Editar</th>
          </tr>
        </thead>
        <tbody>
          {filteredAccountList?.map(( account: IAccountData, index:number ) => (
            <tr key={index}>
              <th>{ index + 1 }</th>
              <td>{ account.dealerNumberId }</td>
              <td>{ account.fordUsername }</td>
              <td>{ account.fordPassword.replace(/./g,'*') }</td>
              <td>{ account.companyName }</td>
              <td>{ account.lastSyncGetLeads === null ? 'S/I' : getFormattedDate( account.lastSyncGetLeads! ) }</td>
              <td>{ account.active ? 'Ativa' : 'Inativa' }</td>
              <th>
                <label htmlFor="editAccountModal" className="btn modal-button" onClick={()=> {setSelectedAccount(account)}}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10" />
                  </svg>
                </label>
                <AccountModal isNewAccount={ false }/>
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export { TableList };