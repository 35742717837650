import React, { useContext, useEffect , useState, useCallback } from 'react';
import axios from "axios";
import { IAccountData } from '../shared/interfaces/IAccountData';
import { IAccountContext } from '../shared/interfaces/IAccountContext';
import { IReactChildren } from '../shared/interfaces/IReactChildren';
  
export const AccountContext = React.createContext<IAccountContext>({} as IAccountContext);

const AccountProvider = ({children} : IReactChildren) => {
  const [accountList, setAccountList] = useState<IAccountData[]>([]);
  const [filteredAccountList, setFilteredAccountList] = useState<IAccountData[] >([]);
  const [selectedAccount, setSelectedAccount] = useState<IAccountData>({} as IAccountData);
  const [isLoading ,setIsLoading] = useState(true);

  const handleSubmitAccount = useCallback(( duplicatedDealerCode : boolean, isNewAccount:boolean ) => {
    selectedAccount.active = (selectedAccount.active !== undefined && selectedAccount.active);
    setSelectedAccount(selectedAccount)
    if (duplicatedDealerCode) {
      alert("Dealer code digitado já existe!\nA conta não foi salva!");
    } else {
      axios({
        method: "post",
        url: `/api/account`,
        auth: {
          username: `${process.env.REACT_APP_API_USERNAME}`,
          password: `${process.env.REACT_APP_API_PASSWORD}`,
        },
        data: selectedAccount,
      })
        .then((response) => {
          if (isNewAccount) {
            setAccountList([...accountList, selectedAccount]);
          } else {
            let newList = accountList.map((account) => {
              if (account.dealerNumberId === selectedAccount.dealerNumberId) {
                account = selectedAccount;
              }
              return account;
            });
            setAccountList(newList);
          }
        })
        .catch((e) => {
          alert("Ocorreu um erro ao salvar a conta!\n" + e);
        })
        .finally(() => {
          resetSelectedAccountState();
          return (event: MouseEvent) => {
            event.preventDefault();
          };
        });
    }
  }, [accountList, selectedAccount]);

  const resetSelectedAccountState = () => {
    const accountInitialState = {
      dealerNumberId: '',
      fordUsername: '',
      fordPassword: '',
      companyName: '',
      lastSyncGetLeads: null,
      active: false
    }
    setSelectedAccount(accountInitialState);
  }

  useEffect(() => {
    axios({
      method: "get",
      url: `/api/account`,
      auth: {
        username: `${process.env.REACT_APP_API_USERNAME}`,
        password: `${process.env.REACT_APP_API_PASSWORD}`,
      },
    }).then((response) => {
      if (response.status === 200) {
        setAccountList(response.data);
        setFilteredAccountList(response.data);
      }
      setIsLoading(false);
      resetSelectedAccountState();
    }).catch((e) => {
      alert('Ocorreu um erro ao carregar as contas!\n' + e);
    });
  }, []); 

  useEffect(() => {
    setAccountContext({
      accountList,
      setAccountList,
      filteredAccountList,
      setFilteredAccountList,
      selectedAccount,
      setSelectedAccount,
      resetSelectedAccountState,
      handleSubmitAccount,
      isLoading,
    });
  },[filteredAccountList, selectedAccount, accountList, handleSubmitAccount, isLoading])

  const [accountContext, setAccountContext] = useState<IAccountContext>({
    accountList,
    setAccountList,
    filteredAccountList,
    setFilteredAccountList,
    selectedAccount,
    setSelectedAccount,
    resetSelectedAccountState,
    handleSubmitAccount,
    isLoading
  });

  return (
    <>
      <AccountContext.Provider value={accountContext}>
        { children }
      </AccountContext.Provider>
    </>
  );
}

const useAccount = () => useContext(AccountContext);

export { AccountProvider, useAccount };