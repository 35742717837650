import { useState } from 'react';
import { useAccount } from '../../hooks/useAccount';
import { IChangeStringEvent, IChangeBooleanEvent } from '../../shared/interfaces/IChangeEvent';
import { IAccountData } from '../../shared/interfaces/IAccountData';
import { Alert } from '../alert/alertComponent';

const AccountModal = ( props: { isNewAccount: boolean }) => {
  const { isNewAccount } = props || false;
  const modalName = isNewAccount ? "newAccountModal" : "editAccountModal";
  const [duplicatedDealerCode, setDuplicatedDealerCode] = useState(false);
  const {
    selectedAccount,
    setSelectedAccount,
    accountList,
    resetSelectedAccountState,
    handleSubmitAccount
  } = useAccount();
  
  const handleInputChange = (event: IChangeStringEvent) => {
    setSelectedAccount({ ...selectedAccount!, [event.target!.name!]: event.target!.value });
  };

  const handleCheckboxInputChange = (event: IChangeBooleanEvent) => {
    setSelectedAccount({ ...selectedAccount!, [event.target!.name!]: event.target.checked });
  };

  const handleDealerNumberChange = (event: IChangeStringEvent) => {
    if ( /^[0-9]+$/.test(event.target!.value!) || event.target.value === '' ) {
      setSelectedAccount({ ...selectedAccount!, [event.target!.name!]: event.target.value });
    }
  };

  const handleModalClose = () => {
    resetSelectedAccountState();
    setDuplicatedDealerCode(false);
  }

  const validateDealerNumberId = (event: IChangeStringEvent) => {
    let account: IAccountData | undefined = accountList.find(
      (account) => account.dealerNumberId.toString() === event.target!.value!.replace(/\D/g, '')
    );
    if (account === undefined || account === null) {
      setDuplicatedDealerCode(false);
    } else {
      setDuplicatedDealerCode(true);
    }
  };

  return (
    <div>
      <input type="checkbox" id={modalName} className="modal-toggle" />
      <div className="modal cursor-pointer">
        <div className="modal-box w-5/12 max-w-5xl">
          <label onClick={handleModalClose} htmlFor={modalName} className="btn btn-sm btn-circle absolute right-2 top-2">✕</label>
          <h2 className="font-bold text-lg">{ isNewAccount ? 'Adicionar conta' : 'Editar conta'}</h2>

          <Alert isHidden={duplicatedDealerCode} type='DANGER' message='Dealer code digitado já existe!' />

          <div className="form-control">
            <label className="label">
              <span className="label-text">Número do dealer</span>
            </label>
            <input
              type="text"
              name="dealerNumberId"
              onChange={(e) => { handleDealerNumberChange(e); validateDealerNumberId(e); }}
              value={selectedAccount?.dealerNumberId}
              placeholder="Código do cliente no Salesforce Ford"
              className="input input-bordered"
              disabled={!isNewAccount}
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Usuário Ford</span>
            </label>
            <input
              type="text"
              name="fordUsername"
              onChange={handleInputChange}
              value={selectedAccount?.fordUsername}
              placeholder="Usuário da api Salesforce Ford"
              className="input input-bordered"
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Senha Ford</span>
            </label>
            <input
              type="password"
              name="fordPassword"
              onChange={handleInputChange}
              value={selectedAccount?.fordPassword}
              placeholder="Senha da api Salesforce Ford"
              className="input input-bordered"
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Empresa</span>
            </label>
            <input
              type="text"
              name="companyName"
              onChange={handleInputChange}
              value={selectedAccount?.companyName}
              placeholder="Nome da empresa"
              className="input input-bordered"
            />
          </div>
          <div className="form-control">
            <label className="label">
              <span className="label-text">Ativo</span>
            </label>
            <input
              type="checkbox"
              name="active"
              onChange={handleCheckboxInputChange}
              checked={selectedAccount?.active}
              defaultChecked={selectedAccount?.active}
              className="toggle"
            />
          </div>

          <div className="modal-action" >
            <label htmlFor={modalName} className="btn" onClick={() => { handleSubmitAccount(duplicatedDealerCode, isNewAccount); resetSelectedAccountState(); handleModalClose(); }}>Salvar</label>
          </div>
        </div>
      </div>
    </div>
  );
}

export { AccountModal };